<template>
	<div class="collect-container">
		<div class="collect-title-box">我的收藏</div>
		<div class="divider"></div>
		<div class="collect-content">
			<div class="content-box">
				<el-tabs v-model="activeName" @tab-click="handleClick" class="tabs-box">
					<el-tab-pane :name="4">
						<span slot="label">拟建信息</span>
					</el-tab-pane>
					<el-tab-pane :name="7">
						<span slot="label">土地交易</span>
					</el-tab-pane>
					<el-tab-pane :name="5">
						<span slot="label">招标信息</span>
					</el-tab-pane>
					<el-tab-pane :name="6">
						<span slot="label">中标信息</span>
					</el-tab-pane>
					<el-tab-pane :name="1">
						<span slot="label">产品超市</span>
					</el-tab-pane>
					<el-tab-pane :name="3">
						<span slot="label">政策规划</span>
					</el-tab-pane>
					<el-tab-pane :name="2">
						<span slot="label">行业动态</span>
					</el-tab-pane>
				</el-tabs>
				<div class="collect-box">
					<div v-if="total == 0" style="margin-top: 150px">
						<empty name="暂无数据"></empty>
					</div>
					<div class="collect-box-item" v-for="(item, index) in tableData" :key="index">
						<el-tooltip effect="light" :content="item.collectionName" placement="bottom-start">
							<div class="collect-item-title" @click="handleDetail(item)">
								{{ item.collectionName }}
							</div>
						</el-tooltip>
						<div class="collect-item-option" @click="delCollect(item)">
							<img @dragstart.prevent src="@/assets/img/user/mycollect.png" alt="" width="100%" height="100%"
								class="item-icon" />
							<span class="item-txt">取消收藏</span>
						</div>
					</div>
				</div>
			</div>
			<div class="pagination-box">
				<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0"></pagination>
			</div>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<ToPayOrMember ref="toPayOrMember" :showCommonText="false" :showPayBtn="showPayBtn" :text="text"></ToPayOrMember>

	</div>
</template>

<script>
import LoginModal from "@/components/loginModal";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import MemberModal from "@/components/MemberModal";
import ToPayOrMember from "@/components/ToPayOrMember"
export default {
	components: {
		Pagination,
		Empty,
		MemberModal,
		LoginModal,
		ToPayOrMember
	},
	data() {
		return {
			total: 0,
			activeName: 4,
			tableData: [],
			params: {
				pageNum: 1,
				pageSize: 10,
			},
			isMember: 0,
			memberObj: '',
			text: "",
			showPayBtn: false,
		};
	},
	computed: {},
	created() {
		this.getInfo();
		this.getCollectionPage(this.activeName);
	},
	mounted() { },
	methods: {
		// 获取用户信息
		getInfo() {
			this.$api.personalCenter
				.getInfo()
				.then(res => {
					this.isMember = res.data.isMember;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 请求收藏列表
		getCollectionPage(val) {
			if (val > 3) {
				val = val + 1
			}
			this.$api.personalCenter.pageCollection({ type: val, ...this.params }).then(res => {
				this.tableData = res.rows;
				this.total = res.total;
			});
		},
		// tab切换
		handleClick(tab) {
			this.params.pageNum = 1;
			this.params.pageSize = 10;
			this.$refs.pagination.page = 1;
			this.getCollectionPage(tab.name);
		},
		// 非会员未购买用户跳转至支付页面，已购买/会员用户跳转至详情页
		handleDetail(val) {
			if (val.isPc != "10011001" && this.activeName < 4) {
				if (val.isApp == "10011001") {
					this.$message.error("无法在PC端查看，请下载APP浏览");
					return;
				}
				if (val.isWechat == "10011001") {
					this.$message.error("无法在PC端查看，请前往微信小程序浏览");
					return;
				}
				this.$message.error("无法在PC端查看");
				return;
			}
			if (this.activeName == 1) {
				// 判断阅读，下载及会员情况
				this.$api.research
					.getReadAndDownload({
						reportId: val.relationId,
					})
					.then(res => {
						let data = res.data;
						if (data.read == "10011001") {
							// 有阅读权限
							if (data.isRead) {
								let url = this.$router.resolve({
									path: `/research/market-detail?type=2&id=${val.relationId}`,
								});
								window.open(url.href, "_blank");
							} else {
								let params = {
									moduleCode: 1001,
									childModuleCode: 10011002,
									type: 2,
								};
								this.$api.industry.getMemberPermission(params).then(res => {
									if (res.data != 0 && res.data > 0) {
										this.open(res.data, val.relationId);
									} else if (res.data < 0) {
										let url = this.$router.resolve({
											path: `/research/market-detail?type=2&id=${val.relationId}`,
										});
										window.open(url.href, "_blank");
									} else {
										this.text = "您的次数已用完，升级VIP会员";
										this.$refs.toPayOrMember.openDialog();
									}
								});
							}
						} else {
							this.$refs.toPayOrMember.openDialog();
							this.showPayBtn = true;
						}
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else if (this.activeName == 2) {
				//行业动态
				this.$api.industry.getIndustryDynamicInfoApp({
					industryDynamicId: val.relationId,
					platform: 1,
				}).then(res => {
					if (res.data) {
						let url = this.$router.resolve({
							path: `/industry/industry-detail?type=1&id=${val.relationId}&fileId=${val.fileId}`,
						});
						window.open(url.href, "_blank");
					} else {
						this.$refs.toPayOrMember.openDialog();
					}
				}) 
			} else if (this.activeName == 3) {
				// 政策规划
				if (window.getSelection().toString() === "") {
					this.$api.industry.selectPolicyPlanUrlById(val.relationId).then(res => {
						if (!res.data) {
							// 判断用户是否登录
							let url = this.$router.resolve({
								path: `/industry/industry-detail?type=2&id=${val.relationId}`,
							});
							if (this.$session.getUsers()) {
								window.open(url.href, "_blank");
							} else {
								this.$refs.loginModal.visible = true;
							}
						} else {
							this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
								confirmButtonText: "确定",
								cancelButtonText: "取消",
								type: "warning",
							}).then(() => {
								window.open(res.data.url, "_blank");
							});
						}
					}).catch(msg => {
						if (msg.code == '10001111') {
							this.$refs.toPayOrMember.openDialog()
							this.text = msg?.msg
						} else {
							this.$message.error(msg?.msg);
						}
					})

				}
			} else if (val.collectionType == "60611007") {
				//拟建
				this.memberObj = {
					moduleCode: '1006',
					childModuleCode: '10061001',
					type: 2
				}
				this.toDetail(val, 1)
			} else if (val.collectionType == "60611009") {
				//招标
				this.memberObj = {
					moduleCode: '1006',
					childModuleCode: '10061003',
					type: 2
				}
				this.toDetail(val, 2)
			} else if (val.collectionType == "60611008") {
				//中标
				this.memberObj = {
					moduleCode: '1006',
					childModuleCode: '10061002',
					type: 2
				}
				this.toDetail(val, 3)
			}else if (val.collectionType == "60611010") {
				//土地
				this.memberObj = {
					moduleCode: '1006',
					childModuleCode: '10061004',
					type: 2
				}
				this.toDetail(val, 4)
			}
		},
		toDetail(val, type) {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.industry.getMemberPermission(this.memberObj).then(res => {
				loading.close();
				if (res.data != 0) {
					let path = ''
					if (type == 1) {
						path = `/industry/proposed-detail?id=${val.relationId}`
					} else if (type == 2) {
						path = `/industry/bidding-detail?id=${val.relationId}`
					} else if (type == 3) {
						path = `/project/bidding-project-detail?id=${val.relationId}`
					}else if (type ==4) {
						path = `/project/land-detail?id=${val.relationId}`
					}
					const routeUrl = this.$router.resolve({
						path: path,
					});
					window.open(routeUrl.href, "_blank");
				} else {
					this.text = "您今日免费次数已使用完，请开通权限后继续使用";
					this.$refs.toPayOrMember.openDialog();
				}
			});
		},

		// 取消收藏
		delCollect(val) {
			let { activeName } = this;
			if (activeName == 1) {
				this.$api.personalCenter.cancel({ collectionType: "60611001", relationId: val.relationId }).then(res => {
					if (res.code == 200) {
						this.$message.success("取消收藏成功");
						this.getCollectionPage(1);
					}
				});
			} else if (activeName == 2) {
				this.$api.personalCenter.cancel({ collectionType: "60611002", relationId: val.relationId }).then(res => {
					if (res.code == 200) {
						this.$message.success("取消收藏成功");
						this.getCollectionPage(2);
					}
				});
			} else if (activeName == 3) {
				this.$api.personalCenter.cancel({ collectionType: "60611004", relationId: val.relationId }).then(res => {
					if (res.code == 200) {
						this.$message.success("取消收藏成功");
						this.getCollectionPage(3);
					}
				});
			} else if (activeName == 4) {
				this.$api.personalCenter.cancel({ collectionType: "60611007", relationId: val.relationId }).then(res => {
					if (res.code == 200) {
						this.$message.success("取消收藏成功");
						this.getCollectionPage(4);
					}
				});
			} else if (activeName == 5) {
				this.$api.personalCenter.cancel({ collectionType: "60611009", relationId: val.relationId }).then(res => {
					if (res.code == 200) {
						this.$message.success("取消收藏成功");
						this.getCollectionPage(5);
					}
				});
			} else if (activeName == 6) {
				this.$api.personalCenter.cancel({ collectionType: "60611008", relationId: val.relationId }).then(res => {
					if (res.code == 200) {
						this.$message.success("取消收藏成功");
						this.getCollectionPage(6);
					}
				});
			} else if (activeName == 7) {
				this.$api.personalCenter.cancel({ collectionType: "60611010", relationId: val.relationId }).then(res => {
					if (res.code == 200) {
						this.$message.success("取消收藏成功");
						this.getCollectionPage(7);
					}
				});
			}
		},
		open(tiems, reportId) {
			this.$confirm(`您还剩余${tiems}次查阅机会，是否消耗1次？`, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					setTimeout(() => {
						let url = this.$router.resolve({
							path: `/research/market-detail?type=2&id=${reportId}`,
						});
						window.open(url.href, "_blank");
					}, 200);
				})
				.catch(() => {
				});
		},
		// 切换分页
		paginChange(page, pageSize) {
			this.params.pageNum = page;
			this.params.pageSize = pageSize;
			this.getCollectionPage(this.activeName);
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
